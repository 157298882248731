import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const UploadDl = (dlName, headers, templateId, data) => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/dl/upload-dl/`, {
    dlName,
    headers,
    templateId,
    data
  });
};

export const GetDl = (tableName, templateId) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/dl/get-dl/?table_name=${tableName}&template_id=${templateId}`
  );
};

export const UpdateDl = (tableName, mail, data) => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/dl/update-dl//?table_name=${tableName}&mail=${mail}`,
    data
  );
};

export const GetDlTables = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/dl/get-dl-tables/`);
};

export const GetDlColumns = templateId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/dl/get-dl-columns/?template_id=${templateId}`
  );
};

export const GetUsers = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/dl/get-users/?product_id=${productId}`
  );
};

export const GetAssets = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/dl/get-assets/?product_id=${productId}`
  );
};

export const AddAsset = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/dl/add-asset/`, data);
};

export const UpdateAssets = data => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/dl/update-assets/`, data);
};

export const AddUser = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/dl/add-user/`, data);
};

export const GetAllUsers = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/dl/get-all-users/`);
};

export const UpdateUsers = data => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/dl/update-users/`, data);
};

export const UpdateAllUsers = data => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/dl/update-all-users/`, data);
};

export const GetAllAssets = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/dl/get-all-assets/`);
};
