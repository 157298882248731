import axios from "./axios";
import { NINJA_3_ENDPOINT, STRIVE_ENDPOINT } from "./endpoint";

export const GetWorkspaces = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/workspaces/`);
};

export const AddWorkSpaces = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/workspaces/add-workspace/`, data);
};

export const SwitchWorkspaces = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/workspaces/switch-workspace/`,
    data
  );
};

export const UpdateWorkspaceName = (workspaceID, data) => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/workspaces/${workspaceID}/`, data);
};

export const ReorderWorkspaces = data => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/workspaces/reorder-workspaces/`,
    data
  );
};

export const DeleteWorkspace = (workspaceID, data) => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/workspaces/${workspaceID}/`, data);
};

export const GetAllComponents = (filter, limit) => {
  let queryFilter = "";
  let queryLimit = "";
  if (filter) {
    queryFilter = `?filter=${filter}`;
  }
  if (limit) {
    queryLimit = queryFilter == "" ? `?limit=${filter}` : `&limit=${filter}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/workspaces/get-all-components/${queryFilter}${queryLimit}`
  );
};

export const InviteMembers = object => {
  return axios.post(
    `${STRIVE_ENDPOINT}/api/v1/ninja-user/invite-member`,
    object
  );
};
