import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use(
  config => {
    const csrftoken = Cookies.get("csrftoken");
    if (
      localStorage.token != null &&
      localStorage.token.toString().trim() != "" &&
      localStorage.token.toString() != "null"
    ) {
      config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["X-CSRFToken"] = csrftoken;
    if (
      localStorage.tenant_id != null &&
      localStorage.tenant_id.toString().trim() != "" &&
      localStorage.tenant_id.toString() != "null"
    ) {
      config.headers["tenant"] = localStorage.tenant_id;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      if (error.response.data.details === "token expired") {
        // window.location = pageUrl.LOGIN;
        return Promise.reject(error);
      } else {
        // window.location = pageUrl.LOGIN;
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
export default axios;
