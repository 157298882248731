import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetManagedUserTables = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/managed-users/`);
};

export const GetManagedUserTableFilter = templateId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/managed-users-filters/?product_template_id=${templateId}`
  );
};

export const GetColumnKeys = (tableId, column, filters, templateId) => {
  let filter = "";
  if (filters) {
    filter = `&filter=${filters}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/managed-users-filters/${tableId}/get-table-column-keys/?template_id=${templateId}&column=${column}${filter}`
  );
};

export const AddManagedUserTableFilter = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/managed-users-filters/`, data);
};

export const UpdateManagedFilter = (filterId, data) => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/managed-users-filters/${filterId}/`,
    data
  );
};

export const UpdateManagedUserTableFilter = data => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/managed-users-filters/update-filters/`,
    data
  );
};

export const DeleteManagedUserTableFilter = id => {
  return axios.delete(`${NINJA_3_ENDPOINT}/api/managed-users-filters/${id}/`);
};

export const GetManagedUserTableData = (
  tableId,
  filters,
  pageSize,
  skip,
  templateId
) => {
  let filter = "";
  if (filters) {
    filter = `&filters=${filters}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/managed-users-filters/${tableId}/get-table-data/?template_id=${templateId}&page_size=${pageSize}&skip=${skip}${filter}`
  );
};
