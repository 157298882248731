import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetDataSources = (limit, skpiTable, skipForm) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-data-sources/?limit=${limit}&skip_table=${skpiTable}&skip_form=${skipForm}`
  );
};

export const GetDataSourceElements = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-data-source-elements/`,
    data
  );
};

export const GetTemplateDataSources = templateId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/product-template-data-source/?product_template_id=${templateId}`
  );
};

export const GetStriveFormElements = formId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/strive-form/?form_id=${formId}`
  );
};

export const GetAllDataSourceElements = (templateId, concatId) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-all-data-source-elements/?product_template=${templateId}&concat_id=${concatId}`
  );
};

export const GetFormData = (formId, formType) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-form-data/?form_id=${formId}&form_type=${formType}`
  );
};

export const GetDataSourcesCondtions = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/ninja-data-source-condition/`);
};

export const GetDataSampleKeys = templateId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-details/get-data-sample-keys/?template_id=${templateId}`
  );
};

export const GetTableColumns = (tableName, schema) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/producttables/get-table-columns/?table_name=${tableName}${
      schema ? "&schema=" + schema : ""
    }`
  );
};

export const GetDataSourceSource = dataSourceId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/${dataSourceId}/get_data_source_source/`
  );
};

export const AddDataSource = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source-details/add-data-source/`,
    data
  );
};

export const AddTemplateDataSource = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/product-template-data-source/`,
    data
  );
};

export const DeleteTemplateDataSource = (
  productTemplate,
  templateDataSource
) => {
  return axios.delete(
    `${NINJA_3_ENDPOINT}/api/product-template-data-source/delete-template-datasource/?template_data_source=${templateDataSource}&product_template=${productTemplate}`
  );
};

export const GetForms = (filter, formId) => {
  let filterValue = "";
  let formValue = "";
  if (filter) {
    filterValue = `?filter=${filter}`;
  }
  if (formId) {
    if (formValue == "") formValue = `?form_id=${formId}`;
    else formValue = `&form_id=${formId}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-form-data-sources/${filterValue}${formValue}`
  );
};

export const GetSearchDataSources = filter => {
  let filterValue = "";
  let formValue = "";
  if (filter) {
    filterValue = `?filter=${filter}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/search-data-sources/${filterValue}${formValue}`
  );
};

export const GetDataSourceColumns = templateId => {
  let filterValue = "";
  if (templateId) {
    filterValue = `?template_id=${templateId}`;
  }
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source/get-datasource-columns/${filterValue}`
  );
};

export const ValidateSql = (sql, templateId) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-data-source-sql/validate-sql/`,
    {
      sql,
      templateId
    }
  );
};

export const CreateBlendingBiew = (templateId, blendingId) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/datablending/create-blending-view/`,
    {
      blendingId,
      templateId
    }
  );
};
