import axios from "./axios";
import { NINJA_3_ENDPOINT, REAL_TIME_ENDPOINT } from "./endpoint";
import qs from "query-string";

export const GetKpiRealTimeData = (
  kpiId,
  templateId,
  email,
  level,
  assetId
) => {
  const queryParams = qs.stringify(
    { kpi_id: kpiId, template_id: templateId, email, level, asset_id: assetId },
    { skipEmptyString: true }
  );
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/real-time/get-kpi-calculation/?${queryParams}`
  );
};

export const GetProductUsers = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/product-data/${productId}/get-users/`
  );
};

export const GetProductRealTimeData = (templateId, email, level, assetId) => {
  const queryParams = qs.stringify(
    { template_id: templateId, email, level, asset_id: assetId },
    { skipEmptyString: true }
  );
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/real-time/get-product-calculation/?${queryParams}`
  );
};

export const CreateMatView = (templateId, forceCreate) => {
  return axios.post(`${REAL_TIME_ENDPOINT}/api/cache/createViewOrRefresh`, {
    templateId,
    forceCreate
  });
};

export const UpdateDlProcedure = templateId => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/update-dl-procedure/`
  );
};

export const UpdateDataSourceProcedure = templateId => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/update-data-source-procedure/`
  );
};
