import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetProductInitials = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/productdetails/get-product-initials/?product_id=${productId}`
  );
};

export const GetProductColumns = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/productdetails/get-product-table-columns/?product_id=${productId}`
  );
};

export const Send = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/sending/send/`, data);
};

export const GetSendingCount = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/sending/get-sending-count/`, data);
};

export const Resend = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/sending/resend/`, data);
};

export const GetResendingCount = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/sending/get-resending-count/`,
    data
  );
};

export const GetDateCounts = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/sending/get-sending-date-counts/`,
    data
  );
};
