import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetSnippets = channel => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/snippets/?channel=${channel}`);
};

export const DeleteSnippet = (snippetId, channel) => {
  return axios.delete(
    `${NINJA_3_ENDPOINT}/api/templatesnippets/${snippetId}/delete-snippet/?channel=${channel}`
  );
};

export const GetDbTables = schemaName => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/producttables/get-schema-tables/?schema=${schemaName}`
  );
};

export const GetLiveData = (tableName, createDateColumn) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/producttables/get-table-data/?table_name=${tableName}&create_date_column=${createDateColumn}`
  );
};

export const GetLiveDataHtml = (mergeTags, template, data) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplatesdetails/substitute-template/`,
    {
      data,
      template,
      mergeTags
    }
  );
};

export const SaveProductTemplate = (
  templateSnippets,
  product,
  dlColumnMappers,
  templateId,
  channel
) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplatesdetails/save-template/`,
    {
      templateSnippets,
      product,
      dlColumnMappers,
      templateId,
      channel
    }
  );
};

export const SaveSnippet = snippet => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplatesdetails/save-snippet/`,
    snippet
  );
};

export const SaveTemplateSnippets = (snippets, channel) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplatesdetails/reorder-snippets/`,
    { snippets, channel }
  );
};

export const SubstituteSnippet = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/snippetdetails/substitute-snippet/`,
    {
      ...data
    }
  );
};

export const SubstituteSnippets = data => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/snippetdetails/substitute-snippets/`,
    data
  );
};

export const UploadStaticImage = (snippetId, data) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/templatesnippets/${snippetId}/upload-static-image/`,
    data
  );
};
