import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";
export const AddProductTemplates = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/producttemplates/`, data);
};

export const UpdateProductTemplate = (templateId, data) => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/`,
    data
  );
};

export const UpdateProductTemplateLogo = (templateId, data) => {
  return axios.put(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/upload-product-logo/`,
    data
  );
};

export const GetProductTemplate = templateId => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/`);
};

export const GetProductTemplates = productId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/producttemplates/?product_id=${productId}`
  );
};

export const GetProductTemplateSnippets = (templateId, channel) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/templatesnippets/?template_id=${templateId}&channel=${channel}`
  );
};

export const RenameProduct = (id, productName) => {
  return axios.put(`${NINJA_3_ENDPOINT}/api/productdetails/rename-product/`, {
    id,
    productName
  });
};

export const AddTemplateToChannel = (channelId, templateSnippetId) => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/templatesnippetschannels/`, {
    channelId,
    templateSnippetId
  });
};

export const GetProductTemplateDl = (templateId, cancelToken) => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${templateId}/get-product-dl/`,
    { cancelToken }
  );
};

export const UpdateProductTemplateDl = productDl => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/producttemplates/${productDl.productTemplateId}/update-product-dl/`,
    productDl
  );
};
