import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const CreateTenant = (name, subdomain) => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant/create/`, {
    name,
    subdomain
  });
};

export const UpdateTenantName = name => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant/update/`, {
    name
  });
};
export const DeleteTenant = () => {
  return axios.delete(`${NINJA_3_ENDPOINT}/api/tenant/delete`);
};
export const FetchTenantInvitations = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/tenant-invitations/list`);
};

export const FetchPermissions = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/tenant/permissions/`);
};
export const InviteTeamMember = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant-invitations/invite/`, data);
};
export const ReinviteTeamMember = id => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant-invitations/reinvite/`, {
    id: id
  });
};
export const RevokeInvitation = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant-invitations/revoke/`, data);
};
export const EditInvitation = data => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/tenant-invitations/edit/`, data);
};
export const GetPermissionByKey = key => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/tenant-invitations/key?key=${key}`);
};
