import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetApps = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/apps/`);
};

export const getAppData = templateId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/app/get-data?template-id=${templateId}`
  );
};

export const editorCreateAppData = (
  product_id,
  name,
  short_name,
  color,
  app_id,
  template_id,
  data_source
) => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/app/set-data`, {
    product_id,
    name,
    short_name,
    color,
    app_id,
    template_id,
    data_source
  });
};
