import axios from "./axios";
import { AUTH_ENDPOINT } from "./endpoint";
import qs from "query-string";

export const FirebaseTokenLogin = id_token => {
  return axios.post(`${AUTH_ENDPOINT}/api/v1/firebase-token-login`, {
    id_token,
    is_app: false
  });
};

export const RegisterUser = id_token => {
  return axios.post(`${AUTH_ENDPOINT}/api/v1/firebase-token-login`, {
    id_token,
    is_app: false
  });
};

export const ResetPassword = ({ email }) => {
  return axios.post(
    `${AUTH_ENDPOINT}/api/v1/reset-password?access_token=9a349e22-d20d-11e9-bb65-2a2ae2dbcce4`,
    {
      email
    }
  );
};

export const VerifyEmail = (verification_code, email) => {
  return axios.post(
    `${AUTH_ENDPOINT}/api/v1/verify-email?access_token=9a349e22-d20d-11e9-bb65-2a2ae2dbcce4`,
    {
      verification_code,
      email
    }
  );
};

export const AutoAuthenticateUser = tenantId => {
  const params = qs.stringify(
    { tenant_id: tenantId, is_app: false },
    {
      skipNull: true,
      skipEmptyString: true
    }
  );
  return axios.get(`${AUTH_ENDPOINT}/api/v1/secured/reauthenticate?${params}`);
};
