import axios from "./axios";
import { NINJA_3_ENDPOINT } from "./endpoint";

export const GetCtlService = npsSurveyId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-services/?api_id=${npsSurveyId}`
  );
};

export const GetCtlRules = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/ctl-rules/`);
};

export const SaveService = service => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/ninja-services/`, service);
};
