const is_dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const env = process.env.REACT_APP_ENV || "development";
const login_env = process.env.REACT_APP_LOGIN_ENV || "development";

export const AUTH_ENDPOINT =
  env === "development"
    ? "https://clojure-authentication-twsbos4a7q-ew.a.run.app"
    : env === "build"
    ? "https://clojure-authentication-twsbos4a7q-ew.a.run.app"
    : env === "live"
    ? "https://clojure-authentication-twsbos4a7q-ew.a.run.app"
    : env === "test"
    ? "https://clojure-authentication-twsbos4a7q-ew.a.run.app"
    : null;

export const CLJS_ENDPOINT =
  env === "development"
    ? "https://clj.test.ninja.usestrive.com"
    : env === "build"
    ? "https://clj.build.ninja.usestrive.com"
    : env === "live"
    ? "https://clj.ninja.usestrive.com"
    : env === "test"
    ? "https://clj.test.ninja.usestrive.com"
    : null;

export const NINJA_3_ENDPOINT =
  env === "development"
    ? "http://localhost:8000"
    : env === "build"
    ? "https://ninja-api.usestrive.com"
    : env === "live"
    ? "https://ninja-live-api.usestrive.com"
    : env === "test"
    ? "https://test-ninja-api.usestrive.com"
    : null;

export const HASURA_ENDPOINT =
  env === "development"
    ? "https://testing-handy-hog-73.hasura.app/v1/graphql"
    : env === "build"
    ? "https://testing-handy-hog-73.hasura.app/v1/graphql"
    : env === "live"
    ? "https://cloud.strive-hasura.online/v1/graphql"
    : env === "test"
    ? "https://testing-handy-hog-73.hasura.app/v1/graphql"
    : null;

export const HASURA_SUBS_ENDPOINT =
  env === "development"
    ? "wss://testing-handy-hog-73.hasura.app/v1/graphql"
    : env === "build"
    ? "wss://testing-handy-hog-73.hasura.app/v1/graphql"
    : env === "live"
    ? "wss://cloud.strive-hasura.online/v1/graphql"
    : env === "test"
    ? "wss://testing-handy-hog-73.hasura.app/v1/graphql"
    : null;

export const REAL_TIME_ENDPOINT =
  env === "development"
    ? "http://localhost:3040"
    : env === "build"
    ? "https://realtimeservice-twsbos4a7q-ew.a.run.app"
    : env === "live"
    ? "https://realtimeservice-twsbos4a7q-ew.a.run.app"
    : env === "test"
    ? "https://realtimeservice-twsbos4a7q-ew.a.run.app"
    : null;

export const SENDING_ENDPOINT = is_dev
  ? "http://localhost:3010"
  : "https://mailservice-twsbos4a7q-ew.a.run.app";

export const STRIVE_ENDPOINT =
  login_env === "development"
    ? "http://localhost:8020"
    : login_env === "test"
    ? "https://build.strive-api.usestrive.com"
    : login_env === "build"
    ? "https://build.strive-api.usestrive.com"
    : login_env === "live"
    ? "https://strive-api.usestrive.com"
    : null;

export const SOCKET_ENDPOINT =
  env === "development"
    ? "ws://34.107.210.203/"
    : env === "test"
    ? "wss://strive-live.site/"
    : env === "build"
    ? "wss://strive-live.site/"
    : env === "live"
    ? "wss://strive-live.site/"
    : null;
