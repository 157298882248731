import axios from "./axios";
import { jsonToBase64 } from "../util/json";

import { NINJA_3_ENDPOINT } from "./endpoint";
let ajaxRequest = null;

export const GetDls = () => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/ninja-dl/`);
};

export const UpdateDl = dl => {
  return axios.post(`${NINJA_3_ENDPOINT}/api/ninja-dl/`, dl);
};

export const DeleteDl = dlId => {
  return axios.delete(`${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/`);
};

export const GetDlColumns = (dlId, cancelToken) => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/get-columns/`, {
    cancelToken: cancelToken
  });
};

export const GetDlUsers = (dlId, pageSize, offset, filters, procedure) => {
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }
  ajaxRequest = axios.CancelToken.source();
  var filterStr = "";
  if (filters && filters.length > 0) {
    filterStr = `&filters=${jsonToBase64(filters)}`;
  }
  var isProcedure = procedure && procedure == true ? "&procedure=true" : "";
  var pageSizeQ = pageSize !== null ? `page_size=${pageSize}` : "";
  var offsetQ = offset !== null ? `&offset=${offset}` : "";

  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/get-users/?${pageSizeQ}${offsetQ}${isProcedure}${filterStr}`,
    { cancelToken: ajaxRequest.token }
  );
};

export const UpdateDlUser = (dlId, user) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/update-user/`,
    user
  );
};

export const UpdateDlUsers = (dlId, users) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/update-users/`,
    users
  );
};

export const DeleteDlUser = (dlId, userId) => {
  return axios.delete(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/delete-user/?user_id=${userId}`
  );
};

export const DeleteDlUses = (dlId, userIds) => {
  let arr = userIds?.join("_") ?? "";
  return axios.delete(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/delete-users/?user_ids=${arr}`
  );
};

export const GetDlWithPermissions = dlId => {
  return axios.get(`${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/`);
};

export const UpdateDlPermission = (dlId, permission) => {
  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/update-permissions/`,
    permission
  );
};

export const DeleteDlPermission = (dlId, permissionId) => {
  return axios.delete(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/delete-permissions/?id=${permissionId}`
  );
};

export const GetDlColumnsType = dlId => {
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/get-dl-columns-type/`
  );
};

export const GetDlColumnValues = (dlId, column, query, cancelToken) => {
  let queryStr = "";
  if (query) queryStr = `&query=${query}`;
  return axios.get(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/dl-column-values/?column=${column}${queryStr}`,
    { cancelToken }
  );
};

export const UpdateDlHierarchy = (
  dlId,
  parentColumnId,
  customLvlId,
  numOfLvls
) => {
  var querParams = "";
  if (typeof parentColumnId == "number" || typeof parentColumnId == "string") {
    if (querParams == "") querParams += "?";
    else querParams += "&";
    querParams += `parent_id=${parentColumnId}`;
  }
  if (typeof customLvlId == "number" || typeof customLvlId == "string") {
    if (querParams == "") querParams += "?";
    else querParams += "&";
    querParams += `custom_lvl_id=${customLvlId}`;
  }
  if (typeof numOfLvls == "number" || typeof numOfLvls == "string") {
    if (querParams == "") querParams += "?";
    else querParams += "&";
    querParams += `num_of_lvls=${numOfLvls}`;
  }

  return axios.post(
    `${NINJA_3_ENDPOINT}/api/ninja-dl/${dlId}/update-hierarchy/${querParams}`
  );
};
